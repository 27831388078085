import React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import linkResolver from '../utils/linkResolver'

import Container from '../components/shared/Container/Container'

// Icons
import IconLoading from '../assets/img/icon-loading.svg'

// Note that the `location` prop is taken and provided to the `usePrismicPreview` hook.
const PreviewPage = ({ isPreview, isLoading }) => {
  return (
    <Container className='flex items-center pt-32 pb-10 px-4'>
      {isPreview ? <><IconLoading className='animate-spin h-5 w-5 text-midnight mr-2' />Loading preview...</> : 'Not a preview!'}
    </Container>
  )
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'taylormadetraining',
  linkResolver
})
